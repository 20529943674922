import React from 'react';
import joinClassNames from '../../utils/className.utils';
import './PageHeader.scss';

type PageHeaderProps = {
  className?: string,
  title: string,
  hasImage?: boolean,
}

const PageHeader: React.FC<PageHeaderProps> = props => {
  const p = props;
  return <header className={joinClassNames(`PageHeader page-section section-page-header`, p.className, p.hasImage ? 'has-featured-image' : '')}>
    <div className="container">
      <h1>{p.title}</h1>
    </div>
  </header>
}

export default PageHeader;