import { graphql } from 'gatsby';
import React from 'react';

import PageHeader from '../components/PageHeader/PageHeader';
import PageSectionPageContent from '../components/PageSectionPageContent/PageSectionPageContent';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import { useHandleThankYouRedirect } from '../hooks/useHandleThankYouRedirect.hook';
import { PageComponent } from '../types/gatsby.types';
import joinClassNames from '../utils/className.utils';

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      ...PageWithFeaturedImageFragment
    }
  }
`;

type PageGenericPageProps = {
  data: any,
}

const PageGenericPage: PageComponent<PageGenericPageProps> = (props) => {
  const {
    data: {
      wpPage: { id, title, content, slug, isFrontPage, featuredImage, seo },
    },
  } = props;

  useHandleThankYouRedirect();

  return (
    <PageTemplate
      {...props} // always include
      wpYoastSEO={seo}
      className={joinClassNames(slug)}
      pageSlug={slug}
      pageTemplate='page'
      title={title}
      isFrontPage={isFrontPage}
      featuredMedia={featuredImage?.node}
    >

      <PageHeader title={title} hasImage={featuredImage?.node} />

      <PageSectionPageContent content={content} />

    </PageTemplate>
  );
};

export default PageGenericPage;
