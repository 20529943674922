import { useControllers } from "../controllers/app.controller";
import { useOnMount } from "./lifecycle.hooks";

export const useHandleThankYouRedirect = () => {
  const { STORE } = useControllers();
  useOnMount(() => {
    const lastForm = STORE.get('last-submitted-form');
    if (lastForm) {
      // ... do something
    } else {
      // console.log('There wasn\'t a submission before.');
      const isThankYouPage = document.body.getAttribute('data-page') === 'thank-you';
      if (isThankYouPage) {
        window.location.href = '/';
      }
    }
  })
}