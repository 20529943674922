import React from 'react';
import { sanitizeAllowExtra } from '../../utils/sanitize.utils';

type PageSectionPageContentProps = {
  content: string,
}

const PageSectionPageContent: React.FC<PageSectionPageContentProps> = props => {
  const p = props;
  return p.content ? <section className="page-section section-page-content">
    <div className="container">
      <div className="page-content" dangerouslySetInnerHTML={{ __html: sanitizeAllowExtra(p.content, ['img'], { allowedAttributes: false }) }} />
    </div>
  </section> : null;
}

export default PageSectionPageContent;
